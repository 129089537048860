import React, { useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import { isBlank } from 'underscore.string';
import NoSsr from '@material-ui/core/NoSsr';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { removeTypename } from 'shared/helpers/utils';

import { HorizontalDivider } from 'components/core';
import CategoryTilesGrid from 'components/category-tiles-grid';
import { MainContent } from 'components/skip-to-main';
import { DesktopOnly, MobileOnly } from 'shared/components';
import { useModals, ModalNames } from 'components/modals';
import useUser from 'hooks/use-user';
import { getPersistedValue } from 'shared/utils/persisted-values';

import useUI from 'hooks/use-ui';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { getHtmlPlainText } from 'shared/utils/misc-utils';
import useDispensaryCategories from 'src/dispensary/hooks/use-dispensary-categories';
import { AmplitudeEvents, useAmplitude } from 'shared/hooks/use-amplitude';
import useCart from 'shared/hooks/use-cart';

import { MenuImageBanner } from 'src/components/menu-image-banner';
import { SponsoredBrandCarousel } from 'src/components/carousels/ads-carousels/sponsored-brand-carousel';
import { PersonalizedProducts } from 'components/carousels/products-carousels/personalized-products-carousel';
import { CategoriesNav } from 'src/components/categories-nav';

import { useHasValidSponsoredBrandPlacement } from 'src/utils/ads/hooks/sponsored-brand';
import { useProductsForHomePage } from 'src/dispensary/hooks/use-products-for-home-page';
import MenuBanner from './components/menu-banner';

const HomepageCarouselSections = dynamic(() =>
  import('components/homepage-carousel-sections').then((mod) => mod.HomepageCarouselSections)
);
const CategoriesCarouselSlider = dynamic(() => import('components/categories-carousel-slider'));

export default function CoreMenuDispensaryPage() {
  const UI = useUI();
  const { dispensary } = useDispensary();
  const { categories } = useDispensaryCategories();
  const amplitude = useAmplitude();
  const Cart = useCart();
  const flags = useFlags();
  const [showCategoriesBorder, setShowCategoriesBorder] = useState(true);
  const dispensaryId = dispensary?.id;
  const { openModal } = useModals();
  const user = useUser();
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  // Show order success modals when viewOrderId is stored in session storage for embedded menus
  const isForceFullWidthCheckoutEnabled = flags['fintech.platform.force-full-width-checkout'] ?? false;

  // don't show anonymous checkout for medical menus/orders ENG-52870
  const useAnonymousKioskCheckout = dispensary?.orderTypesConfigV2.kiosk?.anonymousCheckout && Cart.menuType === `rec`;

  const personalizationCarouselPlacement = flags['growth.ads.personalization-carousel.placement'];
  const hasValidSponsoredBrandPlacement = useHasValidSponsoredBrandPlacement('home-page');

  const productsForHomePage = useProductsForHomePage({ dispensaryId });
  const { personalizedProductsQuery, sponsoredBrandQuery } = productsForHomePage;
  const personalizedProducts = personalizedProductsQuery?.data?.getPersonalizedProducts?.products;
  const sponsoredBrandProducts = sponsoredBrandQuery?.data?.getSponsoredBrand?.products;

  const isCustomizationV2CategoriesEnabled = flags['growth.ecomm.menu-customization-v2-categories.rollout'] ?? false;

  // Don't use Categories bottom border if an ads or personalization carousel is loading below it
  useEffect(() => {
    if (
      (personalizedProducts?.length > 0 && personalizationCarouselPlacement === 'secondary') ||
      sponsoredBrandProducts?.length > 0
    ) {
      setShowCategoriesBorder(false);
    }
  }, [personalizedProducts, sponsoredBrandProducts, personalizationCarouselPlacement]);

  useEffect(() => {
    amplitude.log(AmplitudeEvents.menu.viewHomepage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DOMParser = typeof window !== 'undefined' ? window.DOMParser : null;
  const bannerIsBlank = useMemo(
    () => !dispensary?.menuBannerHtml || isBlank(getHtmlPlainText(dispensary?.menuBannerHtml, DOMParser)),
    [dispensary?.menuBannerHtml, DOMParser]
  );

  const showMenuBanner =
    !bannerIsBlank && UI.isVariant([`store-front`, `embedded`, ...(useAnonymousKioskCheckout ? [`kiosk`] : [])]);

  // Effect to handle page load event
  useEffect(() => {
    function handleLoad() {
      setIsPageLoaded(true);
      window.removeEventListener('load', handleLoad);
    }

    setIsPageLoaded(document.readyState === 'complete');

    if (document.readyState !== 'complete') {
      window.addEventListener('load', handleLoad);
    }
  }, []);

  // Effect to handle modal opening after page load
  useEffect(() => {
    if (
      !isForceFullWidthCheckoutEnabled ||
      !UI.isEmbedded ||
      !dispensary?.embedSettings?.enableFullScreenEmbeddedCheckout ||
      !isPageLoaded
    ) {
      return;
    }

    const storedOrderId = getPersistedValue('viewOrderId');
    const stashedOrder = getPersistedValue('stashedOrder');

    if (storedOrderId) {
      if (!user.hasExternalUser && stashedOrder) {
        // For guest users, show guest checkout success modal with order data
        try {
          Cart.stashedOrder = JSON.parse(stashedOrder);
          UI.viewingGuestOrderSuccess = true;
        } catch (error) {
          // If JSON parsing fails, log error and continue without showing success modal
          console.error('Failed to parse stashed order:', error);
          UI.viewingGuestOrderSuccess = false;
        }
      } else {
        // For logged in users, show order status modal
        user.viewOrderId = storedOrderId;
        openModal(ModalNames.orderStatus);
      }
    }
  }, [
    user,
    openModal,
    Cart,
    UI,
    dispensary?.embedSettings?.enableFullScreenEmbeddedCheckout,
    isForceFullWidthCheckoutEnabled,
    isPageLoaded,
  ]);

  if (!dispensary) {
    return null;
  }

  return (
    <MainContent>
      {!!dispensary?.imageBanners?.length && !UI.isMobileEcommApp && (
        <MenuImageBanner banners={removeTypename(dispensary.imageBanners)} />
      )}

      <NoSsr>
        {showMenuBanner && <MenuBanner htmlContent={dispensary.menuBannerHtml} color={dispensary.menuBannerColor} />}
      </NoSsr>

      {personalizationCarouselPlacement === 'primary' && !UI.isKiosk && (
        <PersonalizedProducts {...productsForHomePage} />
      )}

      <div data-testid='homepage-categories'>
        {isCustomizationV2CategoriesEnabled ? (
          <CategoriesNav categories={categories} showCategoriesBorder={showCategoriesBorder} />
        ) : (
          <>
            <MobileOnly mediaQuery='xs' display='block'>
              <CategoryTilesGrid categories={categories} mt='31px' mb='40px' />
              {showCategoriesBorder && <HorizontalDivider />}
            </MobileOnly>

            <DesktopOnly mediaQuery='sm'>
              <CategoriesCarouselSlider borderBottom={showCategoriesBorder} />
            </DesktopOnly>
          </>
        )}
      </div>

      {personalizationCarouselPlacement === 'secondary' && !UI.isKiosk && (
        <PersonalizedProducts {...productsForHomePage} />
      )}

      {hasValidSponsoredBrandPlacement && (
        <SponsoredBrandCarousel sponsoredBrandQuery={sponsoredBrandQuery} fullWidth />
      )}

      <HomepageCarouselSections dispensary={dispensary} />
    </MainContent>
  );
}
